<template>
  <div class="h-full overflow-y-hidden">
    <div class="absolute right-0 top-0 flex h-full w-1/2 flex-col bg-black-03"></div>
    <div class="relative z-10 flex h-full flex-col overflow-y-auto text-left">
      <div class="flex w-full flex-row">
        <div class="flex w-1/2 flex-col gap-6 pr-8">
          <div>
            <p class="text-subhead-2 mb-2">Send to Queue</p>
            <div class="rounded-lg bg-black-03 p-3">
              <UiInputCheckbox id="call_queue" v-model="modelValue.call_queue" name="call_queue" label="Call queue" />
            </div>
          </div>
          <div>
            <p class="text-subhead-2 mb-2">Select Rotation:</p>
            <div class="rounded-lg bg-black-03 p-3">
              <UiInputRadio
                id="is_default"
                v-model="rotationGroup"
                :items="groupItems"
                class="mb-4"
                vertical
                @update:model-value="updateRotationItems"
              />
              <UiInputSelect
                v-if="rotationGroup === TYPES.CUSTOM || rotationGroup === TYPES.DEFAULT"
                v-model="modelValue.rotation_id"
                :items="rotationItems"
                compact
                name="RotationGroup"
                placeholder="Select rotation group"
                :error="useGetFieldErrors(v$, ['rotation_id'])"
                @update:model-value="setRotationLanguageId"
              />
              <UiInputSelect
                v-if="rotationGroup === TYPES.USER"
                v-model="modelValue.user_id"
                :items="rotationItems"
                compact
                avatar
                group
                name="Users"
                placeholder="Select user"
                :error="useGetFieldErrors(v$, ['user_id'])"
                @update:model-value="setRotationLanguageId"
              />
            </div>
          </div>
          <div>
            <p class="text-subhead-2 mb-2">Update Lead:</p>
            <div class="rounded-lg bg-black-03 p-3">
              <UiInputSelect
                :model-value="rotationGroup === TYPES.DEFAULT ? undefined : modelValue.language_id"
                :items="languagesItems"
                :disabled="rotationGroup === TYPES.DEFAULT"
                compact
                name="Language"
                :placeholder="rotationGroup === TYPES.DEFAULT ? 'Default language in rotation' : 'English'"
                @update:model-value="modelValue.language_id = $event"
              />
              <Transition name="fade" mode="out-in">
                <p v-show="rotationGroup === TYPES.DEFAULT" class="text-caption-2 ml-4 mt-0.5 text-black-80">
                  You can't change the language for the default group, it is set automatically.
                </p>
              </Transition>
            </div>
          </div>
        </div>
        <div class="flex w-1/2 flex-col gap-4 pl-8 text-left">
          <div class="rounded-lg border border-dm-40 px-4 py-3">
            <p class="text-subhead-2 mb-2">Name:</p>
            <p class="text-subhead-3">{{ modelValue.name }}</p>
            <hr class="my-3 border-black-20" />
            <p class="text-subhead-2 mb-2">Parameters:</p>
            <RotationMappingPreview :rules="formattedRules" class="!gap-3" />
          </div>
          <div
            v-if="
              modelValue.call_queue ||
              modelValue.rotation_id ||
              (rotationGroup !== TYPES.DEFAULT && modelValue.language_id)
            "
            class="flex flex-col gap-3 rounded-lg border border-dm-40 px-4 py-3"
          >
            <div
              v-if="modelValue.call_queue"
              class="flex flex-col gap-2 border-b border-dm-40 pb-3 last:border-b-0 last:pb-0"
            >
              <p class="text-subhead-2">Send to Queue</p>
              <p class="text-body-2">Call queue</p>
            </div>
            <div
              v-if="modelValue.rotation_id || modelValue.user_id"
              class="flex flex-col gap-2 border-b border-dm-40 pb-3 last:border-b-0 last:pb-0"
            >
              <p class="text-subhead-2">Select Rotation:</p>
              <p class="text-body-2">
                {{ getChosenGroup }}:
                <span class="text-subhead-3">{{ getChosenRotation }}</span>
              </p>
            </div>
            <div v-if="rotationGroup !== TYPES.DEFAULT && modelValue.language_id" class="flex flex-col gap-2">
              <p class="text-subhead-2">Update Lead:</p>
              <p class="text-body-2">
                Language:
                <span class="text-subhead-3">{{ getChosenLanguage }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 flex flex-row items-center gap-4">
        <div v-if="v$.$errors.length" class="flex flex-row items-start gap-2 text-error-100">
          <UiIcon name="info-circle-filled" />
          <div class="text-caption-2 text-left">
            <span> Each rule consists of conditions and rotation destination.</span> <br />
            <span>Please select the rotation to send leads.</span>
          </div>
        </div>
        <div class="ml-auto flex flex-row items-center gap-4">
          <UiButtonBase id="cancel" type="secondary" :disabled="loading" @click="$emit('back')">
            <UiIcon name="chevron-big-left" />
            Back to edit
          </UiButtonBase>
          <UiButtonBase id="submit" :disabled="loading" @click="submit">{{
            ruleToEdit ? 'Update' : 'Create'
          }}</UiButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { useUiStore } from '~/store/ui'
import type { Mapping, MappingRule, Rotation, InputItem } from '~/types'

const TYPES = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom',
  POOL: 'Pool',
  USER: 'User',
}

const ENGLISH_LANGUAGE_ID = 40

const uiStore = useUiStore()

const emits = defineEmits(['back', 'created'])

type Props = {
  ruleToEdit?: Mapping
}

const props = withDefaults(defineProps<Props>(), {
  ruleToEdit: undefined,
})

const loading = ref(false)
const modelValue = defineModel<Mapping>({ required: true })

const groupItems: InputItem[] = [
  { text: 'Default group', value: TYPES.DEFAULT },
  { text: 'Custom group', value: TYPES.CUSTOM },
  { text: 'Lead pool', value: TYPES.POOL },
  { text: 'User', value: TYPES.USER },
]

const userItems = ref<InputItem[]>([])
const languagesItems = ref<InputItem[]>([])
const defaultRotationGroups = ref<(InputItem & { language: { id: number; name: string } })[]>([])
const customRotationGroups = ref<InputItem[]>([])
const poolRotationGroupId = ref()
const userRotationUserId = ref()
const rotationGroup = ref(props.ruleToEdit ? props.ruleToEdit.rotation.type : TYPES.DEFAULT)

const rotationItems = computed(() => {
  switch (rotationGroup.value) {
    case TYPES.CUSTOM:
      return customRotationGroups.value
    case TYPES.USER:
      return userItems.value
    default:
      return defaultRotationGroups.value
  }
}) as ComputedRef<InputItem[]>

const formattedRules = computed(() => [...modelValue.value.rules, ...modelValue.value.rulesOr!])

const rules = computed(() => ({
  rotation_id: { required: helpers.withMessage(' ', required) },
  user_id: { required: helpers.withMessage(' ', requiredIf(rotationGroup.value === TYPES.USER)) },
}))

const v$ = useVuelidate(rules, modelValue, { $stopPropagation: true })

const getChosenGroup = computed(() => {
  return groupItems.find((item) => item.value === rotationGroup.value)?.text
})

const getChosenRotation = computed(() => {
  return rotationItems.value.find(
    (item: InputItem) =>
      item.value === (modelValue.value.user_id ? modelValue.value.user_id : modelValue.value.rotation_id)
  )?.text
})

const getChosenLanguage = computed(() => {
  return languagesItems.value.find((item: InputItem) => item.value === modelValue.value.language_id)?.text
})

onNuxtReady(
  async () =>
    await Promise.all([
      getUsers(),
      getLanguages(),
      getDefaultRotationGroups(),
      getCustomRotationGroups(),
      getLeadRotationsGroups(),
      getUserRotationsGroups(),
    ])
)

const getUsers = async () => {
  try {
    userItems.value = await useGetUsersItems(true)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getLanguages = async () => {
  try {
    languagesItems.value = await useGetLanguageItems()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getDefaultRotationGroups = async () => {
  try {
    const response = await useGetRotationGroups(TYPES.DEFAULT.toLowerCase())
    defaultRotationGroups.value = response.data.map((i: Rotation) => ({
      value: i.id as number,
      text: i.name,
      language: {
        id: i.language.id,
        name: i.language.name,
      },
    }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getCustomRotationGroups = async () => {
  try {
    const response = await useGetRotationGroups(TYPES.CUSTOM.toLowerCase())
    customRotationGroups.value = useSerializeLibraryItems(response.data)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getLeadRotationsGroups = async () => {
  try {
    const response = await useGetRotationGroups(TYPES.POOL.toLowerCase())
    if (response.data.length) poolRotationGroupId.value = response.data[0]?.id
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getUserRotationsGroups = async () => {
  try {
    const response = await useGetRotationGroups(TYPES.USER.toLowerCase())
    if (response.data.length) userRotationUserId.value = response.data[0]?.id
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const setRotationLanguageId = (rotationId?: number) => {
  if (rotationGroup.value === TYPES.DEFAULT) {
    const rotationLanguage = defaultRotationGroups.value.find(({ value: id }) => id === rotationId)

    modelValue.value.language_id = rotationLanguage?.language.id
  }
}

const setEnglishLanguageId = () => {
  const englishLanguage = languagesItems.value.find(({ value: id }) => id === ENGLISH_LANGUAGE_ID)

  modelValue.value.language_id = englishLanguage?.value
}

const updateRotationItems = () => {
  modelValue.value.rotation_id = undefined
  modelValue.value.user_id = undefined

  if (rotationGroup.value === TYPES.POOL || rotationGroup.value === TYPES.CUSTOM) {
    setEnglishLanguageId()
  }

  v$.value.$reset()
}

const submit = async () => {
  if (rotationGroup.value === TYPES.POOL) {
    modelValue.value.rotation_id = poolRotationGroupId.value
  }

  if (rotationGroup.value === TYPES.USER) {
    modelValue.value.rotation_id = userRotationUserId.value
  }

  const isValid = await v$.value.$validate()
  if (!isValid) return
  loading.value = true
  try {
    const payload = { ...modelValue.value, rules: formattedRules.value }
    payload.rules?.forEach((r: MappingRule, index: number) => {
      r.priority = index + 1
    })
    if (props.ruleToEdit) {
      await useEditMapping(payload)
    } else {
      await useCreateMapping(payload)
    }
    emits('created')
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
